"use strict";
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");

var _Object$keys2 = require("@babel/runtime-corejs3/core-js-stable/object/keys");

var _Object$getOwnPropertySymbols = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols");

var _filterInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/filter");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _Object$getOwnPropertyDescriptors = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors");

var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/defineProperty"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _stringify = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/json/stringify"));

var _splice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/splice"));

var _find = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find"));

var _flags = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/flags"));

var _findIndex = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find-index"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

var _vue = _interopRequireDefault(require("vue"));

var _lodash = _interopRequireDefault(require("lodash"));

var _NetworkState = _interopRequireDefault(require("./state/NetworkState"));

var _BufferState = _interopRequireDefault(require("./state/BufferState"));

var _UserState = _interopRequireDefault(require("./state/UserState"));

var _Message = _interopRequireDefault(require("./Message"));

var Misc = _interopRequireWildcard(require("@/helpers/Misc"));

var TextFormatting = _interopRequireWildcard(require("@/helpers/TextFormatting"));

var _configTemplates = require("@/res/configTemplates");

function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = _Object$keys2(object); if (_Object$getOwnPropertySymbols) { var symbols = _Object$getOwnPropertySymbols(object); if (enumerableOnly) { symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) { return _Object$getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (_Object$getOwnPropertyDescriptors) { Object.defineProperties(target, _Object$getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key)); }); } } return target; }

function createNewState() {
  var stateObj = {
    // May be set by a StatePersistence instance
    persistence: null,
    // Settings may be overridden via config.json
    settings: _configTemplates.configTemplates.default,
    user_settings: {},
    connection: {
      // disconnected / connecting / connected
      status: 'connected',
      sessionId: ''
    },
    ui: {
      active_network: 0,
      active_buffer: '',
      last_active_buffers: [],
      app_has_focus: true,
      app_width: 0,
      app_height: 0,
      is_touch: false,
      is_narrow: false,
      favicon_counter: 0,
      current_input: '',
      input_history: [],
      input_history_pos: 0,
      show_advanced_tab: false
    },
    networks: []
  };
  var userDict = new _vue.default({
    data: function data() {
      return {
        networks: {}
      };
    }
    /*
    (network id): {
        (lowercase nick): UserState,
        (lowercase nick): UserState,
    },
    */

  });
  var bufferDict = new _vue.default({
    data: function data() {
      return {
        networks: {}
      };
    }
    /*
    (network id): [
        BufferState,
        BufferState,
    ]
    */

  }); // Messages are seperate from the above state object to keep them from being reactive. Saves CPU

  var messages = [
    /* {
        networkid: 1,
        buffer: '#kiwiirc',
        messages: [
            Message,
            Message
        ],
    }, */
  ];
  var availableStartups = Object.create(null);
  var state = new _vue.default({
    data: stateObj,
    methods: {
      // Export enough state so that it can be imported in future to resume
      exportState: function exportState(includeBuffers) {
        var toExport = {};

        if (includeBuffers) {
          var _context;

          toExport.networks = (0, _map.default)(_context = state.networks).call(_context, function (network) {
            var _context2;

            var networkObj = {
              id: network.id,
              name: network.name,
              connection: {
                nick: network.connection.nick,
                server: network.connection.server,
                port: network.connection.port,
                tls: network.connection.tls,
                path: network.connection.path,
                password: network.connection.password,
                direct: network.connection.direct,
                encoding: network.connection.encoding,
                bncnetid: network.connection.bncnetid
              },
              auto_commands: network.auto_commands,
              settings: _lodash.default.cloneDeep(network.settings),
              username: network.username,
              gecos: network.gecos,
              password: network.password,
              hidden: network.hidden,
              is_bnc: network.is_bnc,
              buffers: []
            };
            networkObj.buffers = (0, _map.default)(_context2 = network.buffers).call(_context2, function (buffer) {
              var bufferObj = {
                name: buffer.name,
                key: buffer.key,
                enabled: !!buffer.enabled,
                settings: _lodash.default.cloneDeep(buffer.settings),
                latest_messages: []
              };
              buffer.latest_messages.forEach(function (msg) {
                bufferObj.latest_messages.push(msg.serialise());
              });
              return bufferObj;
            });
            return networkObj;
          });
        }

        toExport.user_settings = state.user_settings;
        return (0, _stringify.default)(toExport);
      },
      // Import a previously exported state to continue that state
      importState: function importState(stateStr) {
        var _this = this;

        var importObj = JSON.parse(stateStr);

        if (importObj && importObj.networks) {
          this.resetState();
          importObj.networks.forEach(function (importNetwork) {
            var network = new _NetworkState.default(importNetwork.id, state, userDict, bufferDict);
            network.name = importNetwork.name;
            network.connection = _objectSpread(_objectSpread({}, network.connection), importNetwork.connection);
            network.auto_commands = importNetwork.auto_commands || '';
            network.settings = importNetwork.settings; // First check importNetwork.nick as this was used in older versions
            // TODO: Eventually remove this importNetwork.nick check

            network.nick = importNetwork.nick || importNetwork.connection.nick;

            if (!network.connection.nick && importNetwork.nick) {
              network.connection.nick = importNetwork.nick;
            }

            network.username = importNetwork.username;
            network.gecos = importNetwork.gecos;
            network.password = importNetwork.password;
            network.hidden = importNetwork.hidden;
            network.is_bnc = importNetwork.is_bnc;

            _this.networks.push(network);

            importNetwork.buffers.forEach(function (impBuffer) {
              var buffer = new _BufferState.default(impBuffer.name, network.id, state, messages);
              buffer.key = impBuffer.key;
              buffer.enabled = !!impBuffer.enabled;
              buffer.settings = impBuffer.settings;
              var latestMessages = impBuffer.latest_messages || [];
              latestMessages.forEach(function (msg) {
                buffer.latest_messages.push(new _Message.default(msg));
              });
              network.buffers.push(buffer);
            });
          });
        }

        if (importObj && importObj.user_settings) {
          this.user_settings = importObj.user_settings;
        }
      },
      resetState: function resetState() {
        this.$set(this.$data, 'user_settings', {});
        this.$set(this.$data, 'networks', []);
        (0, _splice.default)(messages).call(messages, 0);
      },
      setting: function setting(name, val) {
        if (typeof val !== 'undefined') {
          if (val === this.getSetting('settings.' + name)) {
            // Remove setting from user_settings if its the default
            return this.setSetting('user_settings.' + name, null);
          } // Setting any setting always goes into the user own settings space


          return this.setSetting('user_settings.' + name, val);
        } // Check the user specific settings before reverting to global settings


        var userSetting = this.getSetting('user_settings.' + name);
        var result = typeof userSetting !== 'undefined' ? userSetting : this.getSetting('settings.' + name);
        return result;
      },
      // Accept 'dotted.notation' to read a state property of any depth
      getSetting: function getSetting(name) {
        var parts = name.split('.');
        var val = this.$data;

        for (var i = 0; i < parts.length; i++) {
          val = val[parts[i]];

          if (typeof val === 'undefined') {
            break;
          }
        }

        return val;
      },
      // Accept 'dotted.notation' to set a state property of any depth
      setSetting: function setSetting(name, newVal) {
        var parts = name.split('.');
        var val = this.$data;

        for (var i = 0; i < parts.length; i++) {
          var propName = parts[i];
          var nextVal = val[propName];

          if (i < parts.length - 1 && typeof nextVal === 'undefined') {
            nextVal = this.$set(val, propName, {});
          } else if (i === parts.length - 1) {
            if (newVal === null) {
              this.$delete(val, propName);
            } else {
              this.$set(val, propName, newVal);
            }
          }

          val = nextVal;
        }

        return val;
      },
      getActiveNetwork: function getActiveNetwork() {
        return this.getNetwork(this.ui.active_network);
      },
      getNetwork: function getNetwork(networkid) {
        var network = (0, _find.default)(_lodash.default).call(_lodash.default, this.networks, {
          id: networkid
        });
        return network;
      },
      getNetworkFromAddress: function getNetworkFromAddress(netAddr) {
        return (0, _find.default)(_lodash.default).call(_lodash.default, this.networks, function (net) {
          var isMatch = netAddr.toLowerCase() === net.connection.server.toLowerCase();
          return isMatch;
        });
      },
      getNetworkFromBncNetId: function getNetworkFromBncNetId(bncnetid) {
        return (0, _find.default)(_lodash.default).call(_lodash.default, this.networks, function (net) {
          return bncnetid === net.connection.bncnetid;
        });
      },
      addNetwork: function addNetwork(name, nick, serverInfo) {
        // Find the current largest ID and increment it by 1
        function networkidReduce(currentMax, network) {
          return network.id > currentMax ? network.id : currentMax;
        }

        var networkid = serverInfo.channelId ? parseInt(serverInfo.channelId, 10) : _lodash.default.reduce(this.networks, networkidReduce, 0) + 1;
        var network = new _NetworkState.default(networkid, state, userDict, bufferDict);
        network.name = name;
        network.username = serverInfo.username;
        network.gecos = serverInfo.gecos;
        network.password = serverInfo.account_password || '';
        network.connection.password = serverInfo.password;
        network.connection.nick = nick;
        network.connection.server = serverInfo.server || '';
        network.connection.port = serverInfo.port || 6667;
        network.connection.tls = serverInfo.tls || false;
        network.connection.path = serverInfo.path || '';
        network.connection.direct = !!serverInfo.direct;
        network.connection.path = serverInfo.path || '';
        network.connection.encoding = serverInfo.encoding || 'utf8';
        network.connection.bncnetid = serverInfo.bncnetid || '';

        if (serverInfo.services) {
          network.services = serverInfo.services;
        }

        this.networks.push(network); // Add the server server buffer

        this.addBuffer(network.id, '*').joined = true;
        var eventObj = {
          network: network
        };
        state.$emit('network.new', eventObj);
        return network;
      },
      removeNetwork: function removeNetwork(networkid) {
        var _context3;

        var network = this.getNetwork(networkid);

        if (!network) {
          return;
        }

        if (network.state === 'connected') {
          network.ircClient.quit();
        }

        while (network.buffers.length > 0) {
          this.removeBuffer(network.buffers[0]);
        }

        var findNewNetwork = false;

        if (network === this.getActiveNetwork()) {
          findNewNetwork = true;
        }

        var idx = this.networks.indexOf(network);
        (0, _splice.default)(_context3 = this.networks).call(_context3, idx, 1);

        if (findNewNetwork) {
          this.openLastActiveBuffer();
        }

        var eventObj = {
          network: network
        };
        state.$emit('network.removed', eventObj);
      },
      getActiveBuffer: function getActiveBuffer() {
        return this.getBufferByName(this.ui.active_network, this.ui.active_buffer);
      },
      setActiveBuffer: function setActiveBuffer(networkid, bufferName) {
        if (!networkid) {
          this.ui.active_network = 0;
          this.ui.active_buffer = '';
        } else {
          if (this.settings.useBufferHistory && this.ui.active_network) {
            // Keep track of last 20 viewed buffers. When closing buffers we can go back
            // to one of the previous ones
            this.ui.last_active_buffers.push({
              networkid: this.ui.active_network,
              bufferName: this.ui.active_buffer
            });
            var lastActive = this.ui.last_active_buffers;
            this.ui.last_active_buffers = (0, _splice.default)(lastActive).call(lastActive, lastActive.length - 20);
          }

          this.ui.active_network = networkid;
          this.ui.active_buffer = bufferName; // Clear any unread messages counters for this buffer

          var buffer = this.getBufferByName(networkid, bufferName);

          if (buffer && (0, _flags.default)(buffer).unread) {
            (0, _flags.default)(buffer).unread = 0;
          } // Update the buffers last read time


          if (buffer) {
            buffer.markAsRead(true);
          }
        }
      },
      openLastActiveBuffer: function openLastActiveBuffer() {
        // if not using buffer history, just unset the active buffer
        if (!this.settings.useBufferHistory) {
          this.setActiveBuffer();
          return;
        }

        var targetNetwork;
        var targetBuffer;
        var lastActive = this.ui.last_active_buffers; // Find the last buffer in our history that still exists

        for (var i = lastActive.length - 1; i >= 0; i--) {
          var network = this.getNetwork(lastActive[i].networkid);

          if (!network) {
            continue;
          }

          var buffer = network.bufferByName(lastActive[i].bufferName);

          if (!buffer) {
            continue;
          }

          targetNetwork = network;
          targetBuffer = buffer; // Trim the buffer history to this point

          (0, _splice.default)(lastActive).call(lastActive, i);
          break;
        } // Try to find a suitable buffer


        if (!targetBuffer) {
          var _network = this.getActiveNetwork() || this.networks[0];

          if (_network) {
            targetNetwork = _network;
            var _buffer = _network.buffers[1];

            if (_buffer) {
              targetBuffer = _buffer;
            } else {
              targetBuffer = _network.serverBuffer();
            }
          }
        }

        if (targetBuffer) {
          this.setActiveBuffer(targetNetwork.id, targetBuffer.name);
        } else {
          this.setActiveBuffer();
        }
      },
      updateBufferLastRead: function updateBufferLastRead(networkid, bufferName) {
        var buffer = this.getBufferByName(networkid, bufferName);

        if (buffer) {
          buffer.last_read = Date.now();
          buffer.active_timeout = null;
        }
      },
      getOrAddBufferByName: function getOrAddBufferByName(networkid, bufferName) {
        var network = this.getNetwork(networkid);

        if (!network) {
          return null;
        }

        var toMatch = bufferName.toLowerCase();
        var buffer = (0, _find.default)(_lodash.default).call(_lodash.default, network.buffers, function (b) {
          return b.name.toLowerCase() === toMatch;
        });

        if (!buffer) {
          buffer = this.addBuffer(networkid, bufferName);
        }

        return buffer;
      },
      getBufferByName: function getBufferByName(networkid, bufferName) {
        if (!bufferName) {
          return null;
        }

        var network = this.getNetwork(networkid);

        if (!network) {
          return null;
        }

        var toMatch = bufferName.toLowerCase();
        var buffer = (0, _find.default)(_lodash.default).call(_lodash.default, network.buffers, function (b) {
          return b.name.toLowerCase() === toMatch;
        });
        return buffer;
      },
      addBuffer: function addBuffer(networkid, bufferName) {
        if (!bufferName) {
          return false;
        } // If we already have this buffer, don't re-add it


        var buffer = this.getBufferByName(networkid, bufferName);

        if (buffer) {
          return buffer;
        } // Make sure we at least we have this network


        var network = this.getNetwork(networkid);

        if (!network) {
          return false;
        }

        buffer = new _BufferState.default(bufferName, network.id, state, messages);
        network.buffers.push(buffer);
        var eventObj = {
          buffer: buffer
        };
        state.$emit('buffer.new', eventObj);
        return buffer;
      },
      removeBuffer: function removeBuffer(buffer) {
        var isActiveBuffer = this.getActiveBuffer() === buffer;
        var network = this.getNetwork(buffer.networkid);

        if (!network) {
          return;
        }

        var eventObj = {
          buffer: buffer
        };
        state.$emit('buffer.close', eventObj);
        var bufferIdx = network.buffers.indexOf(buffer);

        if (bufferIdx > -1) {
          var _context4;

          (0, _splice.default)(_context4 = network.buffers).call(_context4, bufferIdx, 1);
        }

        var messageIdx = (0, _findIndex.default)(_lodash.default).call(_lodash.default, messages, {
          networkid: network.id,
          buffer: buffer.name
        });

        if (messageIdx > -1) {
          (0, _splice.default)(messages).call(messages, messageIdx, 1);
        }

        if (buffer.isChannel() && buffer.joined) {
          network.ircClient.part(buffer.name);
        } // Remove the user from network state if no remaining common channels


        if (buffer.isQuery()) {
          var remainingBuffers = state.getBuffersWithUser(network.id, buffer.name);

          if (remainingBuffers.length === 0) {
            state.removeUser(network.d, {
              nick: buffer.name
            });
          }
        }

        if (isActiveBuffer) {
          this.openLastActiveBuffer();
        } // Remove this buffer from any users

        /* eslint-disable guard-for-in */


        (0, _keys.default)(buffer.users).forEach(function (nick) {
          var user = buffer.users[nick];
          delete user.buffers[buffer.id];
        });
      },
      addMessage: function addMessage(buffer, message) {
        // Some messages try to be added after a network has been removed, meaning no buffer
        // will be available
        if (!buffer || !buffer.getNetwork()) {
          return;
        }

        var user = this.getUser(buffer.networkid, message.nick);
        var bufferMessage = new _Message.default(message, user);

        if (user && user.ignore) {
          bufferMessage.ignore = true;
        }

        buffer.addMessage(bufferMessage); // Increment the unread counter if this buffer is not active

        var includeAsActivity = false;
        var typesForActivty = ['privmsg', 'action', 'notice', 'wallops'];

        if (buffer.setting('traffic_as_activity') && message.type === 'traffic') {
          typesForActivty.push('traffic');
        }

        if (typesForActivty.indexOf(message.type) > -1) {
          includeAsActivity = true;
        }

        var isActiveBuffer = buffer.networkid === this.ui.active_network && buffer.name === this.ui.active_buffer;
        var network = buffer.getNetwork();
        var isNewMessage = message.time >= buffer.last_read;
        var isHighlight = !network ? false : Misc.mentionsNick(bufferMessage.message, network.ircClient.user.nick);

        if (message.type && message.type === 'invite') {
          // Invites should be treated as highlights
          isHighlight = true;
        } // Check for extra custom highlight words


        var extraHighlights = (state.setting('highlights') || '').toLowerCase().split(' ');

        if (!isHighlight && extraHighlights.length > 0) {
          extraHighlights.forEach(function (word) {
            if (!word) {
              return;
            }

            if (bufferMessage.message.toLowerCase().indexOf(word) > -1) {
              isHighlight = true;
            }
          });
        }

        if (state.setting('teamHighlights')) {
          var m = bufferMessage.message;
          var patterns = {
            everyone: /(^|\s)@everybody($|\s|[,.;])/,
            channel: /(^|\s)@channel($|\s|[,.;])/,
            here: /(^|\s)@here($|\s|[,.;])/
          };

          if (m.match(patterns.everyone) || m.match(patterns.channel)) {
            isHighlight = true;
          }

          if (m.match(patterns.here) && network && !network.away) {
            isHighlight = true;
          }
        }

        bufferMessage.isHighlight = isHighlight;

        if (isNewMessage && isActiveBuffer && state.ui.app_has_focus) {
          buffer.last_read = message.time;
        } // Handle buffer flags


        if (isNewMessage && includeAsActivity && !isActiveBuffer && !bufferMessage.ignore) {
          buffer.incrementFlag('unread');

          if (isHighlight) {
            buffer.flag('highlight', true);
          }
        } // Handle any notifications


        var settingAlertOn = buffer.setting('alert_on');
        var isSelf = !network ? false : message.nick === network.nick;

        if (isNewMessage && settingAlertOn !== 'never' && message.type !== 'nick' && message.type !== 'mode' && message.type !== 'traffic' && !buffer.isSpecial() && !bufferMessage.ignore && !isSelf) {
          var notifyTitle = '';
          var notifyMessage = message.nick ? message.nick + ': ' : '';
          notifyMessage += message.message;

          if (isHighlight) {
            notifyTitle = TextFormatting.t('notification_mentioned', {
              channel: buffer.name
            });
          } else if (settingAlertOn === 'message' && !isHighlight) {
            notifyTitle = buffer.name;
          }

          if (notifyTitle) {
            this.$emit('notification.show', notifyMessage, {
              title: notifyTitle,
              onclick: function onclick() {
                state.setActiveBuffer(buffer.networkid, buffer.name); // Newer webkit browser use parent.focus() while older webkit uses
                // window.focus()

                /* eslint-disable no-restricted-globals */

                if (parent && parent.focus) {
                  parent.focus();
                }

                if (window.focus) {
                  window.focus();
                }
              }
            });
          }
        }

        if (isActiveBuffer && !state.ui.app_has_focus && message.type !== 'traffic' && (buffer.setting('flash_title') === 'message' || buffer.setting('flash_title') === 'highlight' && isHighlight)) {
          this.$emit('notification.title', true);
        }

        this.$emit('message.new', {
          message: bufferMessage,
          buffer: buffer
        });
      },
      addMessageNoRepeat: function addMessageNoRepeat(buffer, message) {
        // Add a message to the buffer only if it does not match the previous message
        if (!buffer || !buffer.getNetwork()) {
          // Some messages try to be added after a network has been removed,
          // meaning no buffer will be available
          return;
        }

        var lastMessage = buffer.getLastMessage();

        if (lastMessage && lastMessage.message === message.message) {
          return;
        }

        this.addMessage(buffer, message);
      },
      getUser: function getUser(networkid, nick, usersArr_) {
        var user = null;
        var users = usersArr_;

        if (!users) {
          var network = this.getNetwork(networkid);

          if (network) {
            users = network.users;
          }
        }

        if (users) {
          user = users[nick.toLowerCase()];
        }

        return user;
      },
      // Modify a networks user array without hitting vues reactive system until fn()
      // has completed. Good for making large changes in bulk
      usersTransaction: function usersTransaction(networkid, fn) {
        var network = this.getNetwork(networkid);

        if (!network) {
          return;
        }

        var users = _lodash.default.clone(network.users);

        fn(users);
        network.users = users;
      },
      addUser: function addUser(networkid, user, usersArr_) {
        var network = null; // Accept either a network ID or a direct network object

        if (typeof networkid === 'number') {
          network = this.getNetwork(networkid);
        } else {
          network = networkid;
        }

        if (!network) {
          return null;
        }

        var usersArr = usersArr_ || network.users;
        var userObj = null;

        if (!usersArr[user.nick.toLowerCase()]) {
          userObj = usersArr[user.nick.toLowerCase()] = new _UserState.default(user);
        } else {
          // Update the existing user object with any new info we have
          userObj = state.getUser(network.id, user.nick, usersArr);

          _lodash.default.each(user, function (val, prop) {
            if (typeof val !== 'undefined') {
              userObj[prop] = val;
            }
          });
        }

        return userObj;
      },
      removeUser: function removeUser(networkid, user) {
        var network = this.getNetwork(networkid);

        if (!network) {
          return;
        }

        var buffers = state.getBuffersWithUser(networkid, user.nick);
        buffers.forEach(function (buffer) {
          state.removeUserFromBuffer(buffer, user.nick);
        });
        this.$delete(network.users, user.nick.toLowerCase());
      },
      addMultipleUsersToBuffer: function addMultipleUsersToBuffer(buffer, newUsers) {
        var _this2 = this;

        var network = this.getNetwork(buffer.networkid);

        var bufUsers = _lodash.default.clone(buffer.users);

        state.usersTransaction(network.id, function (users) {
          newUsers.forEach(function (newUser) {
            var user = newUser.user;
            var modes = newUser.modes;
            var userObj = state.getUser(network.id, user.nick, users);

            if (!userObj) {
              userObj = _this2.addUser(network, user, users);
            }

            bufUsers[userObj.nick.toLowerCase()] = userObj; // Add the buffer to the users buffer list

            if (!userObj.buffers[buffer.id]) {
              state.$set(userObj.buffers, buffer.id, {
                modes: modes || [],
                buffer: buffer
              });
            } else {
              userObj.buffers[buffer.id].modes = modes || [];
            }
          });
        });
        buffer.users = bufUsers;
      },
      addUserToBuffer: function addUserToBuffer(buffer, user, modes) {
        var network = this.getNetwork(buffer.networkid);
        var userObj = state.getUser(network.id, user.nick);

        if (!userObj) {
          userObj = this.addUser(network, user);
        } else {
          // Verify the user object is correct
          _lodash.default.each(user, function (val, prop) {
            if (userObj[prop] !== val) {
              userObj[prop] = val;
            }
          });
        }

        buffer.addUser(userObj); // Add the buffer to the users buffer list

        if (!userObj.buffers[buffer.id]) {
          state.$set(userObj.buffers, buffer.id, {
            modes: modes || [],
            buffer: buffer
          });
        } else {
          state.$set(userObj.buffers[buffer.id], 'modes', modes || []);
        }
      },
      removeUserFromBuffer: function removeUserFromBuffer(buffer, nick) {
        buffer.removeUser(nick);
      },
      getBuffersWithUser: function getBuffersWithUser(networkid, nick) {
        var network = this.getNetwork(networkid);

        if (!network) {
          return [];
        }

        var normalisedNick = nick.toLowerCase();
        var buffers = [];
        network.buffers.forEach(function (buffer) {
          var bufferNameLower = buffer.name.toLowerCase();

          if (buffer.users[normalisedNick] || normalisedNick === bufferNameLower) {
            buffers.push(buffer);
          } else if (nick === network.nick && buffer.isQuery()) {
            buffers.push(buffer);
          }
        });
        return buffers;
      },
      changeUserNick: function changeUserNick(networkid, oldNick, newNick) {
        var network = this.getNetwork(networkid);

        if (!network) {
          return;
        }

        var user = state.getUser(network.id, oldNick);

        if (!user) {
          return;
        }

        var normalisedNew = newNick.toLowerCase();
        var normalisedOld = oldNick.toLowerCase();
        user.nick = newNick; // If the nick has completely changed (ie. not just a case change) then update all
        // associated buffers user lists

        if (normalisedOld !== normalisedNew) {
          state.$set(network.users, normalisedNew, network.users[normalisedOld]);
          state.$delete(network.users, normalisedOld);
          (0, _keys.default)(user.buffers).forEach(function (bufferId) {
            var buffer = user.buffers[bufferId].buffer;
            state.$set(buffer.users, normalisedNew, buffer.users[normalisedOld]);
            state.$delete(buffer.users, normalisedOld);
          });
        }

        var buffer = this.getBufferByName(network.id, oldNick);

        if (buffer) {
          buffer.rename(newNick);
        }
      },
      getStartups: function getStartups() {
        return availableStartups;
      }
    }
  });
  return state;
}

var instance = null;

function singleton() {
  instance = instance || createNewState();
  return instance;
}

singleton.create = function createState() {
  return createNewState();
};

singleton.recreate = function recreateState() {
  if (instance) {
    var _context5;

    instance.resetState();
    instance.$destroy(); // eslint-disable-next-line

    (0, _splice.default)(_context5 = instance._watchers).call(_context5, 0, instance._watchers.length);
  }

  instance = createNewState();
  return instance;
};

var _default = singleton;
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
window._kiwi_exports["libs"]["state"]
window._kiwi_exports.libs.state = exports.default ? exports.default : exports;
